<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import CheckCustomer from './components/check_customer.vue';

formCreate.component('CheckCustomerDiscount', CheckCustomer);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('fee_discount_good_form');
    const rule = [];
    this.rule.forEach((v) => {
      const rowData = v;
      if (rowData.field === 'customerName') {
        rowData.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
        rowData.restfulParams = {
          enableStatus: '009',
        };
        rowData.headers = { functionCode: 'select-customer' };
        rowData.props = {
          ...rowData.props,
          remoteParams: 'customerName',
          remote: true,
          filterable: true,
        };
        rowData.optionsKey = {
          value: 'customerCode',
          label: 'customerName',
        };
        rowData.on = {
          ...rowData.on,
          change: (val) => this.setcustomerName(val, rowData.options),
        };
      }
      if (rowData.field === 'saleCompanyCode') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.setValue({
              saleCompanyName: rowData.options.filter((a) => a.dictCode === val)[0].dictValue,
            });
          },
        };
      }
      if (rowData.field === 'customerCode') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'operationType') {
        rowData.props = {
          ...rowData.props,
          disabled: !this.formConfig.isDetail,
        };
      }
      if (rowData.field === 'payType') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.setValue({
              discountType: '',
              goodsProductLevelCode: '',
              goodsProductCode: '',
            });
            console.log(rowData.options);
            const { poolType } = rowData.options.find((a) => a.value === val);
            console.log(poolType);
            if (poolType === 'discount') {
              this.hiddenFields(false, ['discountType']);
              this.hiddenFields(true, ['goodsProductLevelCode', 'goodsProductCode']);
            } else {
              this.hiddenFields(false, ['goodsProductLevelCode', 'goodsProductCode']);
              this.hiddenFields(true, ['discountType']);
            }
          },
        };
      }
      if (rowData.field === 'files') {
        rowData.props = {
          ...rowData.props,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          accept: '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg',
        };
      }
      if (rowData.field === 'goodsProductLevelCode') {
        rowData.restful = '/mdm/mdmProductLevelController/productLevelSelectList';
        rowData.headers = { functionCode: 'select-product-level' };
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.getRule('goodsProductCode').restfulParams = {
              allUnderThisProductLevelCode: val,
            };
            this.setValue({
              goodsProductCode: '',
            });
          },
        };
        rowData.props = {
          ...rowData.props,
          remoteParams: 'productLevelName',
          remote: true,
          filterable: true,
        };
        rowData.optionsKey = {
          value: 'productLevelCode',
          label: 'productLevelName',
        };
      }
      if (rowData.field === 'goodsProductCode') {
        rowData.restful = '/mdm/mdmProductController/productSelectList';
        rowData.headers = { functionCode: 'select-goods' };
        rowData.props = {
          ...rowData.props,
          remoteParams: 'productName',
          remote: true,
          filterable: true,
        };
        rowData.refresh = true;
        rowData.restfulParams = {
          allUnderThisProductLevelCode: '',
        };
        rowData.optionsKey = {
          value: 'productCode',
          label: 'productName',
        };
      }
      rule.push(rowData);
    });
    this.rule = rule;
    this.reload(rule);
    this.afterReload();
    this.getSomeOptions();
  },
  methods: {
    afterReload() {
      this.hiddenFields(true, ['discountType', 'goodsProductLevelCode', 'goodsProductCode']);
    },
    // 获取支付方式，费用池类型，调整类型等options
    getSomeOptions() {
      let path = '/fee/feePoolGoodsController/queryForAdjust';
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDetailController/queryForAdjustDetail';
      }
      request
        .get(path)
        .then((res) => {
          if (res.success) {
            const operationType = this.getRule('operationType');
            operationType.options = res.result.operationTypeSelectList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            const payType = this.getRule('payType');
            payType.options = res.result.payTypeList.map((v) => ({
              value: v.value,
              label: v.text,
              poolType: v.poolType,
            }));
            const discountType = this.getRule('discountType');
            discountType.options = res.result.discountTypeList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
          }
        });
    },
    setcustomerName(val, options) {
      this.customerName = options.find((v) => v.customerCode === val).customerName;
      this.setValue({
        customerCode: val,
      });
    },
    // 提交
    async submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.getFormData(),
        customerName: this.customerName,
      };
      if (params.goodsProductCode && typeof params.goodsProductCode !== 'string') {
        params.goodsProductCode = params.goodsProductCode.join(',');
      }

      let path = '/fee/feePoolGoodsController/adjust';
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDetailController/adjust';
      }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      return true;
    },
  },
};
</script>
