<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      dictCodeList: {},
    };
  },
  components: {
  },
  async mounted() {
    const rule = await this.getBeforFormRule('fee_discount_goods_adjust2');
    const dictCodeList = [];
    rule.map((v) => {
      const rowData = v;
      let isDisabled = false;
      switch (rowData.field) {
        case 'customerName': isDisabled = true; break;
        case 'customerCode': isDisabled = true; break;
        case 'discountType': isDisabled = true; break;
        case 'goodsProductName': isDisabled = true; break;
        case 'goodsProductLevelName': isDisabled = true; break;
        case 'payType': isDisabled = true; break;
        default: isDisabled = false;
          break;
      }
      rowData.props = {
        ...rowData.prop,
        disabled: isDisabled,
      };
      return rowData;
    });
    this.rule = rule;
    this.reload(rule);
    this.afterReload();
    if (this.formConfig.poolCode || this.formConfig.poolDetailCode) {
      this.getData();
    }
  },
  methods: {
    afterReload() {
      this.hiddenFields(true, ['discountType', 'goodsProductLevelName', 'goodsProductName']);
    },
    getData() {
      let path = '/fee/feePoolGoodsController/queryForAdjustByPoolCode';
      let params = {};
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDetailController/queryForAdjustByPoolDetailCode';
        params = {
          poolDetailCode: this.formConfig.poolDetailCode,
        };
      } else {
        params = {
          poolCode: this.formConfig.poolCode,
        };
      }
      request
        .get(path, params)
        .then((res) => {
          if (res.success) {
            const operationType = this.getRule('operationType');
            operationType.options = res.result.operationTypeSelectList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            const payType = this.getRule('payType');
            payType.options = res.result.payTypeList.map((v) => ({
              value: v.value,
              label: v.text,
              poolType: v.poolType,
            }));
            const discountType = this.getRule('discountType');
            discountType.options = res.result.discountTypeList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            this.setValue({
              ...res.result,
              fileList: [],
            });
            if (res.result.poolType === 'discount') {
              this.hiddenFields(false, ['discountType']);
            } else {
              this.hiddenFields(false, ['goodsProductLevelName', 'goodsProductName']);
            }
          }
        });
    },

    // 提交
    submit() {
      if (!this.getFormData()) return false;
      let path = '/fee/feePoolGoodsController/adjustByPoolCode';
      let params = {};
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDetailController/adjustByPoolDetailCode';
        params = {
          ...this.getFormData(),
          poolDetailCode: this.formConfig.poolDetailCode,
        };
      } else {
        params = {
          ...this.getFormData(),
          poolCode: this.formConfig.poolCode,
        };
      }
      delete params.list;
      // if (this.formConfig.btnCode === 'frozen') {
      //   params.adjustType = '8';
      //   params.adjustFee = params.frozenFee;
      //   params.adjustReason = params.frozenReason;
      // } else if (this.formConfig.btnCode === 'thaw') {
      //   params.adjustType = '9';
      //   params.adjustFee = params.frozenFee8;
      //   path = '/dms/dms/m/rebateFeePool/unfrozenItem';
      // }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
